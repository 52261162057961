import React from 'react';

import logo from '../../assets/logo.png';

export type HeaderProps = {
    title: string;
};

const Header: React.FC<HeaderProps> = React.memo(
    ({title}) => {

        const date = new Date().toLocaleString();

        return(
            <header className='relative bg-gray-50 shadow-sm p-4'>
                <section className='flex flex-row '>
                    <img className='flex flex-row h-16 ' alt='loopfront logo' src={logo} />
                    <h1 className='flex flex-col text-4xl md:text-5xl font-medium my-2 mx-3'>{title}</h1>
                    <section className='p-3 pr-5 absolute right-0 text-gray-500 text-sm'>
                        <h1 className='font-semibold'>Retrieved:</h1>
                        {date}
                    </section>
                </section>
            </header>
        )
    }
);

export default Header;
