import React from 'react';

import InfoIcon from './InfoIcon';
import ProgressHalf from '../../graphs/ProgressHalf';

export type MaterialStatusType = {
    title: string;
    departed: number;
    reserved: number;
    additional: string;
    tooltip: string;
}

const MaterialStatusTile: React.FC<MaterialStatusType> = React.memo(
    ({title, departed, reserved, additional, tooltip}) => {

        return(
            <section className='w-full 2xl:w-1/3 flex flex-col pl-4 pt-2 pb-10 bg-white shadow rounded-md'>
                <section className='relative flex flex-row'>
                    <h1 className='text-xl pl-2 pt-7'>{title}</h1>
                    <InfoIcon margin={'8'} title={title} content={tooltip}/>
                </section>
                <h2 className='pl-2 font-semibold tracking-wider text-md' style={{color: "#94A3B8"}}>{additional}</h2>
                <section className='relative flex flex-row'>
                        <section className='pl-2 pt-12 flex flex-row'>
                            <h1 className='text-4xl font-semibold' style={{color: "#4A5568"}}>{departed}</h1>
                            <h1 className='text-xl font-semibold pt-3 pl-2 text-gray-400'>{`/ ${(reserved+departed).toLocaleString()}`}</h1>
                        </section>
                        <ProgressHalf
                            realised_tip={'Realised'} 
                            potential_tip={'Potential'} 
                            category={departed} 
                            tooltip={tooltip} 
                            total={reserved+departed} 
                        />
                </section>
            </section>
        )

    }
);

export default MaterialStatusTile;
