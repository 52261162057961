import React from 'react';
import LoadingBar from '../../graphs/LoadingBar';
import Percentage from '../../report/subComponents/Percentage';

export type CategoryTileProps = {
    title: string;
    category: number;
    total: number;
    tooltip: string;
}

const CategoryTile: React.FC<CategoryTileProps> = React.memo(
    ({title, category, total, tooltip}) => {
        return(
            <section className='w-full xl:w-1/3 pl-4 pt-2 pb-3 flex flex-col bg-white shadow rounded-md'>
                <section className='relative flex flex-row'>
                    <h3 className='font-sans pl-2 pt-4 pb-2 font-medium text-md' style={{color: "#64748B"}}>{title}</h3>
                    <Percentage title={title} category={category} total={total} tooltip={tooltip}/>
                </section>

                <section className='pr-4'>
                    <LoadingBar title={title} category={category} total={total}/>
                </section>

                <section className='flex flex-row'>
                    <h2 className='px-2 font-semibold text-3xl' style={{color: "#4A5568"}}>{category.toLocaleString()}</h2>
                    <h2 className='pt-2 font-semibold tracking-wider text-md' style={{color: "#94A3B8"}}>{`/ ${total.toLocaleString()}`}</h2>
                </section>
            </section>
        )
    }
);

export default CategoryTile;
