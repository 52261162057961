import React from 'react';

import InfoIcon from '../report/subComponents/InfoIcon';


export type TileProps = {
    title: string;
    value: any;
    additional: string;
    category: number;
    total: number;
    tooltip: string;
    description: string | boolean;
}

const Tile: React.FC<TileProps> = React.memo(
    ({title, value, additional, category, total, description, tooltip}) => {

        return(
            <section className='lg:w-1/2 w-full pl-4 pb-3 relative flex flex-col bg-white shadow rounded-md'>
                <section className='flex flex-row'>
                    <h3 className='font-sans pl-4 pt-4 pb-2 font-medium text-md' style={{color: "#64748B"}}>{title}</h3>
                    { tooltip? <InfoIcon margin={'4'} title={title} content={tooltip}/> : ''}
                </section>
                <h2 className='px-4 font-semibold text-3xl' style={{color: "#4A5568"}}>{value}</h2>
                <h2 className='pl-4 pt-1 font-semibold tracking-wider text-md' style={{color: "#94A3B8"}}>{additional}</h2>
                <h2 className='pl-4 pt-1 font-semibold tracking-wider text-xs' style={{color: "#94A3B8"}}> {description ? description : ''}</h2>
            </section>
        )
    }
);

export default Tile;
