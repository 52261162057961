import React from 'react';

import { Location } from '../../API';


import {
    ResponsiveContainer,
    BarChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Bar
} from 'recharts';

type SimpleBarChartProps = {
    title: string | undefined;
    locations: Location[] | null;
}

type DataFormat = {
    name: string
    value: number
}

const SimpleBarChart: React.FC<SimpleBarChartProps> = React.memo(
    ({title, locations}) => {

        const locationData: DataFormat[] = []

        locations ? locations.forEach((loc: Location) => {
            locationData.push(
                {
                    name: loc.name,
                    value: loc.residing_total_amount
                }
            )
        }) : console.log("Barchart error")

        return(
            <div className='border grid grid-rows pt-4' style={{borderColor: "#CBD5E1"}}>
                <h3 className='text-xl pl-6 pb-10'>{title}</h3>
                <ResponsiveContainer height={300} width="95%">
                    <BarChart data={locationData}>
                        <CartesianGrid 
                            vertical={false} 
                            stroke={"#D1D5DB"} 
                            strokeDasharray="3"
                        />
                        <XAxis 
                            tickLine={false} 
                            dataKey="name" 
                            stroke={"#4B5563"}
                        />
                        <YAxis 
                            tickLine={false}
                            axisLine={false}
                        />
                        <Bar 
                            dataKey="value"
                            fill="#4B5563"
                        />
                        <Tooltip cursor={false}/>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        );
    }
);

export default SimpleBarChart;