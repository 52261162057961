import React, { useEffect, useState } from 'react';

import { fetchOrganization, Organization } from './API';

import Header, { HeaderProps } from "./components/header/Header";
import Overview from './components/overview/Overview';
import TotalSavingsContainer from './components/report/TotalSavingsContainer';
import MaterialContainer from './components/overview/MaterialContainer';
import LocationSavingsContainer from './components/report/LocationSavingsContainer';
import Footer from './components/header/Footer';
import PageLoading from './assets/PageLoading';

const headerProps: HeaderProps = {
  title: 'Insight'
};

function App() {
  const [organization, setOrganization] = useState<Organization>();
  const [load, setLoad] = useState(true);

  useEffect(() => {
    fetchOrganization(window.location.href.substring(window.location.href.indexOf('=')+1))
      .then(result => result.json())
      .then((result) => {
        setOrganization(result.organization)
        setLoad(false)
      })
      .catch(error => {
        console.log(error)
        window.location.replace('https://www.loopfront.com')
      });
  },[])

  return (
    <div className='font-sans flex flex-col flex-grow	space-y-8 text-gray-700 bg-gray-100'>
    {
      load? <PageLoading />  : 
        <div>
          <Header title = {headerProps.title}/>
          <div className='space-y-6 lg:px-60'>
            <Overview organization={organization ? organization : null}/>
            <MaterialContainer organization={organization ? organization : null}/>
            <TotalSavingsContainer organization={organization ? organization : null}/>
            <LocationSavingsContainer locations={organization ? organization.locations : []}/>
          </div>
          <Footer />
        </div>
    }
    </div>
  );
}

export default App;
