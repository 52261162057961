import React from 'react';

import { ResponsiveContainer, Pie, PieChart, Cell } from 'recharts';

export type ProgressHalfType = {
    realised_tip: string;
    potential_tip: string;
    tooltip: string;
    category: number;
    total: number;
}

const ProgressHalf: React.FC<ProgressHalfType> = React.memo(
    ({realised_tip, potential_tip, tooltip, category, total}) => {

        const usage = [
            {name: realised_tip, value: category},
            {name: potential_tip, value: total-category}
        ]
        
        const COLORS = [
            '#0369A1',
            '#E2E8F0'
        ]

        return(
            <div className='w-1/2 pt-3 absolute right-0 mr-2'>
                <ResponsiveContainer height={180} width={200}>
                    <PieChart margin={{ top: -30, left: 0, right: 40, bottom: 0 }}>
                        <Pie
                            data={usage}
                            dataKey='value'
                            innerRadius={50}
                            outerRadius={75}
                            startAngle={180}
                            endAngle={0}
                        >
                        {
                            usage.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]}/>)
                        }
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
            </div>
        )
    }
)

export default ProgressHalf;
