import React from 'react';

type LegendProps = {
    title: string | undefined;
    fill: string;
}

const Legend: React.FC<LegendProps> = React.memo(
    ({title, fill}) => {

        return(
            <section className='flex flex-row pt-3 pb-3'>
                <section className='h-5 w-5' style={{backgroundColor: `${fill}`}} />
                <h1 className='pl-3 text-sm font-thin text-gray-600'>{title}</h1>
            </section>
        );
    }
);

export default Legend;
