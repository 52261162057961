import React from 'react';

import {
    ResponsiveContainer,
    BarChart,
    XAxis,
    YAxis,
    Tooltip,
    Bar
} from 'recharts';


type LoadingBarProps = {
    title: string | undefined;
    category: number;
    total: number;
}

const LoadingBar: React.FC<LoadingBarProps> = React.memo(
    ({title, category, total}) => {

        const barData = []
        const other = total - category

        barData.push(
            {
                name: title,
                category: category,
                other: other
            }
        )

        return(
            <ResponsiveContainer width='100%' height={30}>
                <BarChart data={barData} layout='vertical' margin={{top: 0, right: 0, left: -50, bottom: -30}}>
                    <YAxis type='category' dataKey='name' tick={false} tickLine={false} axisLine={false}/>
                    <XAxis type='number' tick={false} tickLine={false} axisLine={false}/>
                    <Bar barSize={5} dataKey='category' stackId='a' fill='#0369A1' />
                    <Bar barSize={5} dataKey='other' stackId='a' fill='#CBD5E1' />
                    <Tooltip cursor={false}/>
                </BarChart>
            </ResponsiveContainer>
        );
    }
);

export default LoadingBar;
