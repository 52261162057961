import React from 'react';

import Tile from './Tile';
import { Organization } from '../../API';


interface OverviewProps {
    organization: Organization | null;
}

const Overview: React.FC<OverviewProps> = React.memo(
    ({organization}) => {
        const value_specified = (
            (organization? organization.total_value? organization.total_value.amount_defined : 0 : 0) 
            /(organization? organization.total_value? organization.total_value.amount_total : 1 : 1)
        )*100;

        const co2_specified = (
            (organization? organization.total_co2? organization.total_co2.amount_defined : 0 : 0) 
            /(organization? organization.total_co2? organization.total_co2.amount_total : 1 : 1)
        )*100;

        const weight_specified = (
            (organization? organization.total_weight? organization.total_weight.amount_defined : 1 : 1) 
            /(organization? organization.total_weight? organization.total_weight.amount_total : 1 : 1)
        )*100;

        return(
            <section className='pb-6 container mx-auto flex flex-col'>
                <h2 className='pt-4 pb-4 pl-6 text-2xl'> {organization ? organization.name : ' '}</h2>
                <section className='w-full px-4 flex flex-col lg:flex-col xl:flex-row space-y-4 xl:space-x-10 xl:space-y-0'>
                    <section className='lg:w-full xl:w-1/2 flex md:flex-row flex-col space-y-4 md:space-y-0 md:space-x-4 xl:space-x-10'>
                        <Tile
                            title={"Locations"}
                            value={(organization ? organization.total_locations : 0).toLocaleString()}
                            additional={`${(organization ? organization.total_amount : 0).toLocaleString()} Materials`}
                            category={0}
                            total={0}
                            tooltip={''}
                            description={false}
                        />
                        <Tile
                            title={"Total Value"}
                            value={`${(organization ? organization.total_value ? organization.total_value.value : 0 : 0).toLocaleString()} kr`}
                            additional={''}
                            category={organization ? organization.total_value ? organization.total_value.amount_defined : 0 : 0}
                            total={organization ? organization.total_value ? organization.total_value.amount_total : 0 : 0}
                            tooltip={`This does not represent all materials. Since only ${value_specified.toFixed(1)} percent of all materials have a value specified, this number is not representative for all materials. Please specify the value for more materials to get more accurate reports.`}
                            description={`${value_specified.toFixed(1)}% with value specified`}
                        />
                    </section>
                    <section className='lg:w-full xl:w-1/2 flex md:flex-row flex-col space-y-4 md:space-y-0 md:space-x-4 xl:space-x-10'>
                        <Tile
                            title={"Total Co2"}
                            value={`${(organization ? organization.total_co2 ? organization.total_co2.value : 0 : 0).toLocaleString()} kg`}
                            additional={''}
                            category={organization ? organization.total_co2 ? organization.total_co2.amount_defined : 0 : 0}
                            total={organization ? organization.total_co2 ? organization.total_co2.amount_total : 0 : 0}
                            tooltip={`This does not represent all materials. Since only ${co2_specified.toFixed(1)} percent of all materials have a co2 value specified, this number is not representative for all materials. Please specify the co2 value for more materials to get more accurate reports.`}
                            description={`${co2_specified.toFixed(1)}% with co2 specified`}
                        />
                        <Tile
                            title={"Total Weight"}
                            value={`${(organization ? organization.total_weight ? organization.total_weight.value : 0 : 0).toLocaleString()} kg`}
                            additional={``}
                            category={organization ? organization.total_weight ? organization.total_weight.amount_defined : 0 : 0}
                            total={organization ? organization.total_weight ? organization.total_weight.amount_total : 0 : 0}
                            tooltip={`This does not represent all materials. Since only ${weight_specified.toFixed(1)} percent of all materials have a weight specified, this number is not representative for all materials. Please specify the weight for more materials to get more accurate reports.`}
                            description={`${weight_specified.toFixed(1)}% with weight specified`}
                        />
                    </section>
                </section>
            </section>
        )
    }
)

export default Overview;
