import React from 'react';

import { Organization } from '../../API';
import SavingsTile from './subComponents/SavingsTile';
import Disclaimer from './subComponents/Disclaimer';

interface TotalSavingsProps {
    organization: Organization | null;
}

const TotalSavingsContainer: React.FC<TotalSavingsProps> = React.memo(
    ({organization}) => {

        const financialTooltip = 'This tile presents estimated financial savings. The realised savings are the value from materials that have been re-used. The planned savings are from materials currently reserved - that have yet to be transported and used. When these materials are transported to their respective location and taken in use, the planned savings will become realised. These savings are only taking materials with a specified value into account.';
        const co2Tooltip = 'This tile presents estimated emission (co2) savings. The realised savings are the specified co2 from materials that have been re-used. The planned savings are from materials currently reserved - that have yet to be transported and used. When these materials are transported to their respective location and taken in use, the planned co2 savings will become realised. These savings are only taking materials with a specified co2 value into account.';
        const wasteTooltip = 'This tile presents estimated waste savings. The realised savings are the combined weight of materials that have been re-used. The planned savings are from materials currently reserved - that have yet to be transported and used. When these materials are transported to their respective location and taken in use, the planned savings will become realised. These savings are only taking materials with a specified weight into account.';

        return(
            <section className=' container py-2 mx-auto flex flex-col' style={{borderColor: "#CBD5E1"}}>
                <h1 className='text-xl pl-6 pt-4'> Estimated Savings for {organization ? organization.name : ''} </h1>
                <section className='px-6 pt-6 pb-3 flex flex-col sm:flex-col xl:flex-row space-y-4 xl:space-y-0 xl:space-x-10'>
                    <SavingsTile 
                        title = {'Financial Savings'}
                        unit = {'NOK'}
                        type = {'kr'}
                        real = {organization ? organization.real_savings ? organization.real_savings.value ? organization.real_savings.value.value : 0 : 0 : 0}
                        planned = {organization? organization.planned_savings? organization.planned_savings.value ? organization.planned_savings.value.value : 0: 0 : 0}
                        total = {organization? organization.total_value? organization.total_value.value : 0 : 0}
                        tooltip = {financialTooltip}
                        fill_real = {'#0E7490'}
                        fill_planned = {'#75C0D8'}
                    />
                    <SavingsTile 
                        title = {'Emission Savings'}
                        unit = {'Co2'}
                        type = {'kg'}
                        real = {organization? organization.real_savings? organization.real_savings.co2? organization.real_savings.co2.value : 0 : 0 : 0}
                        planned = {organization? organization.planned_savings? organization.planned_savings.co2? organization.planned_savings.co2.value : 0 : 0 : 0}
                        total = {organization? organization.total_co2? organization.total_co2.value : 0 : 0}
                        tooltip = {co2Tooltip}
                        fill_real = {'#0D9488'}
                        fill_planned = {'#73C3BE'}
                    />
                    <SavingsTile 
                        title = {'Waste Savings'}
                        unit = {'Weight'}
                        type = {'kg'}
                        real = {organization? organization.real_savings? organization.real_savings.weight? organization.real_savings.weight.value: 0 : 0 : 0}
                        planned = {organization? organization.planned_savings? organization.planned_savings.weight? organization.planned_savings.weight.value: 0 : 0 : 0}
                        total = {organization? organization.total_weight? organization.total_weight.value : 0 : 0}
                        tooltip = {wasteTooltip}
                        fill_real = {'#D97706'}
                        fill_planned = {'#EBBD8A'}
                    />
                </section>
                <section className='px-6'>
                    <Disclaimer 
                        title = {'Important:'}
                        content = {'All savings and estimates presented in this report are based on information specified by the customer in the Loopfront ecosystem. The savings are based on current and previous reservations of materials. However; savings from materials re-used before October 2020 will not be included as Loopfront did not collect this information prior to this.'}
                    /> 
                </section>
            </section>
        )
    }
)

export default TotalSavingsContainer;
