export interface Location {
    amount: ReportCategory
    weight: NestedReportCategory
    co2: NestedReportCategory
    value: NestedReportCategory
    coordinates: []
    name: string
    residing_total_amount: number
    residing_amount_in_use: number
    residing_amount_available: number
}

export interface Organization {
    name: string
    total_locations: number
    total_amount: number
    total_weight: Category
    total_co2: Category
    total_value: Category
    total_redesign: number
    total_hazardous: number
    total_recycling: number
    locations: Location[] | null
    planned_savings: Savings
    real_savings: Savings
}

export interface Category {
    value: number
    amount_defined: number
    amount_total: number
}

export interface Savings {
    amount: number
    weight: Category
    co2: Category
    value: Category
}

export interface ReportCategory {
    incoming: number
    arrived: number
    outgoing: number
    departed: number
}

export interface NestedReportCategory {
    incoming: Category
    arrived: Category
    outgoing: Category
    departed: Category
}

export const fetchOrganization = async(hash: string) => {
    const endpoint = `https://app.loopfront.com/api/report_with_token/get_data/?token=${hash}`;
    console.log(hash)
    const data = await (await fetch(endpoint));

    if(data.status === 200){
    }
    else{
        window.location.replace('https://www.loopfront.com')
    }

    return data;
};
