import React from 'react';
import { Organization } from '../../API';

import SimpleBarChart from '../graphs/SimpleBarChart';
import CategoryTile from '../report/subComponents/CategoryTile';

export type MaterialContainerProps = {
    organization: Organization | null;
}

const MaterialContainer: React.FC<MaterialContainerProps> = React.memo(
    ({organization}) => {
        return(
            <section className='px-6 container mx-auto flex flex-col'>
                <SimpleBarChart
                title = {'Material on Locations'}
                locations = {organization ? organization.locations : []}
                />
                <section className='w-full pt-6 rounded-sm flex flex-col xl:flex-row space-y-4 xl:space-y-0 xl:space-x-10'>
                    <CategoryTile 
                    title = {'Materials Marked for Redesign'}
                    category = {organization ? organization.total_redesign : 0}
                    total = {organization ? organization.total_amount : 0}
                    tooltip = {'Percentage of materials that are marked for redesign'}
                    />
                    <CategoryTile 
                    title = {'Identified Hazardous Materials'}
                    category = {organization ? organization.total_hazardous : 0}
                    total = {organization ? organization.total_amount : 0}
                    tooltip = {'Percentage of materials that are marked as hazardous'}
                    />
                    <CategoryTile 
                    title = {'Materials Marked for Recycling'}
                    category = {organization ? organization.total_recycling : 0}
                    total = {organization ? organization.total_amount : 0}
                    tooltip = {'Percentage of materials that are marked for recycling'}
                    />
                </section>
            </section>
        )
    }
);

export default MaterialContainer;
