import React from 'react';

const Loopfront: React.FC = () => {
    return(
        <svg className='ml-14' width="253" height="29" viewBox="0 0 253 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
            <path d="M37.3682 1.61111H41.9673V19.1032H52.5453L51.9704 22.9008H37.3682V1.61111Z" fill="#4B5668"/>
            <path d="M69.3321 14.8452C69.3321 19.6786 66.4576 23.246 61.3986 23.246C56.4545 23.246 53.5801 19.6786 53.5801 14.9603C53.5801 10.0119 56.5695 6.55951 61.6286 6.55951C66.3427 6.55951 69.3321 9.78173 69.3321 14.8452ZM58.0642 14.8452C58.0642 17.9524 59.444 19.7936 61.5136 19.7936C63.6982 19.7936 64.8479 17.9524 64.8479 14.9603C64.8479 11.623 63.5832 10.0119 61.3986 10.0119C59.329 10.0119 58.0642 11.5079 58.0642 14.8452Z" fill="#4B5668"/>
            <path d="M86.8087 14.8452C86.8087 19.6786 83.9342 23.246 78.8752 23.246C73.9311 23.246 71.0566 19.6786 71.0566 14.9603C71.0566 10.0119 74.0461 6.55951 79.1051 6.55951C83.8192 6.55951 86.8087 9.78173 86.8087 14.8452ZM75.5408 14.8452C75.5408 17.9524 76.9205 19.7936 78.9901 19.7936C81.1747 19.7936 82.3245 17.9524 82.3245 14.9603C82.3245 11.623 81.0597 10.0119 78.8752 10.0119C76.8056 10.0119 75.5408 11.5079 75.5408 14.8452Z" fill="#4B5668"/>
            <path d="M93.8223 21.4048V29H89.4531V10.7024C89.4531 9.43652 89.4531 8.17065 89.4531 6.78969H93.7073C93.8223 7.25001 93.8223 8.17065 93.8223 8.74604C94.5122 7.59525 95.8919 6.32938 98.4214 6.32938C101.986 6.32938 104.745 9.32144 104.745 14.2699C104.745 19.9087 101.641 23.0159 97.7316 23.0159C95.6619 23.246 94.5122 22.4405 93.8223 21.4048ZM100.376 14.6151C100.376 11.7381 99.2263 10.0119 97.0417 10.0119C94.5122 10.0119 93.5923 11.623 93.5923 14.8452C93.5923 17.9524 94.3972 19.6786 96.8117 19.6786C99.2263 19.6786 100.376 17.9524 100.376 14.6151Z" fill="#4B5668"/>
            <path d="M108.08 22.9008V10.2421H105.78V6.90476H108.08V5.52381C108.08 2.18651 109.69 0 113.139 0C113.829 0 114.749 0.115079 115.208 0.230159V3.68254C114.979 3.68254 114.634 3.56746 114.289 3.56746C113.024 3.56746 112.449 4.02778 112.449 5.75397V6.90476H115.208V10.2421H112.449V22.9008H108.08Z" fill="#4B5668"/>
            <path d="M117.163 11.1627C117.163 9.5516 117.163 8.17065 117.163 6.78969H121.532C121.647 7.13493 121.647 8.74604 121.647 9.5516C122.337 7.71033 124.062 6.32938 126.821 6.32938V10.5873C123.602 10.4722 121.647 11.3929 121.647 15.7659V22.7857H117.163V11.1627Z" fill="#4B5668"/>
            <path d="M143.838 14.8452C143.838 19.6786 140.963 23.246 135.904 23.246C130.96 23.246 128.086 19.6786 128.086 14.9603C128.086 10.0119 131.075 6.55951 136.134 6.55951C140.734 6.55951 143.838 9.78173 143.838 14.8452ZM132.455 14.8452C132.455 17.9524 133.835 19.7936 135.904 19.7936C138.089 19.7936 139.239 17.9524 139.239 14.9603C139.239 11.623 137.974 10.0119 135.789 10.0119C133.835 10.0119 132.455 11.5079 132.455 14.8452Z" fill="#4B5668"/>
            <path d="M146.483 10.9325C146.483 9.55157 146.483 8.05554 146.483 6.90475H150.737C150.852 7.36507 150.852 8.40078 150.852 8.97618C151.427 7.94046 152.691 6.55951 155.451 6.55951C158.67 6.55951 160.74 8.74602 160.74 12.7738V22.9008H156.371V13.2341C156.371 11.3928 155.796 10.127 153.841 10.127C152.002 10.127 150.852 11.1627 150.852 14.0397V22.9008H146.368V10.9325H146.483Z" fill="#4B5668"/>
            <path d="M162.81 6.90475H165.224V2.53174H169.708V6.90475H172.813V10.2421H169.708V18.1825C169.708 19.4484 170.053 19.9087 171.433 19.9087C171.663 19.9087 172.123 19.9087 172.468 19.9087V22.9008C171.548 23.246 170.398 23.246 169.478 23.246C166.374 23.246 165.339 21.5198 165.339 18.7579V10.2421H162.925V6.90475H162.81Z" fill="#4B5668"/>
            <path d="M5.40381 8.51587L8.6232 6.90475L11.8426 8.51587L8.6232 10.2421L5.40381 8.51587Z" fill="#36A16A"/>
            <path d="M13.4523 24.8571V21.6349L8.6232 19.1032V10.2421L5.40381 8.51587V20.5992L13.4523 24.8571Z" fill="#28684A"/>
            <path d="M21.7306 17.377V20.5992L13.4521 24.8571V21.6349L17.9363 19.3333L21.7306 17.377Z" fill="#36A16A"/>
            <path d="M7.47359 0L0 3.79762L3.21939 5.40873L7.47359 3.22222L18.2815 8.74603L21.5009 7.13492L7.47359 0Z" fill="#28684A"/>
            <path d="M0 10.8175L3.21939 12.6587V5.40875L0 3.79764V10.8175Z" fill="#36A16A"/>
            <path d="M27.5947 4.25793L24.3753 5.86905V8.97619L13.5674 14.5L11.1528 13.3492V16.5714L13.5674 17.7222L27.5947 10.5873V7.36508V4.25793Z" fill="#28684A"/>
            <path d="M19.891 0.345245L16.7866 2.07144L24.3752 5.86905L27.5946 4.25794L19.891 0.345245Z" fill="#36A16A"/>
            <path d="M177.756 5.86903V2.87696H176.722V1.95633H179.826V2.87696H178.791V5.86903H177.756Z" fill="#4B5668"/>
            <path d="M183.965 5.86903V3.33728L183.045 5.86903H182.585L181.666 3.33728V5.86903H180.631V1.95633H182.011L182.815 4.02776L183.62 1.95633H185V5.75395H183.965V5.86903Z" fill="#4B5668"/>
            </g>
            <defs>
            <clipPath id="clip0">
            <rect width="252.586" height="29" fill="white" transform="translate(0.229492)"/>
            </clipPath>
            </defs>
        </svg>
    )
};

export default Loopfront;
