import React from 'react';

import { ResponsiveContainer, Pie, Tooltip, Label, PieChart, Cell } from 'recharts';

import Legend from './Legend';

export type ProgressWheelType = {
    description: string;
    real: number;
    planned: number;
    total: number;
    unit: string;
    type: string;
    fill_real: string;
    fill_planned: string;
}

const ProgressWheel: React.FC<ProgressWheelType> = React.memo(
    ({description, real, planned, total, fill_real, fill_planned, unit, type}) => {

        const usage = [
            {name: `Realised savings (${unit})`, value: real},
            {name: `Planned savings (${unit})`, value: planned},
            {name: `Total specified ${unit.toLowerCase()} of all materials`, value: total}
        ]
        
        const COLORS = [
            fill_real,
            fill_planned,
            '#E2E8F0'
        ]

        const percentage = ((real)/total)*100

        return(
            <section className='flex sm:flex-row xl:flex-col flex-col relative'>
                <section className='w-full md:w-1/2 pl-6 xl:pl-14 md:pl-0 items-center'>
                <ResponsiveContainer height={190} width={220}>
                    <PieChart margin={{ top: 0, left: 20, right: 0, bottom: 0 }}>
                        <Pie
                            data={usage}
                            dataKey='value'
                            innerRadius={55}
                            outerRadius={90}
                            startAngle={180}
                            endAngle={-180}
                            >
                        {
                            usage.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]}/>)
                        }
                        <Label
                            className='text-3xl font-semibold'
                            value={`${percentage.toFixed(1)}%`} 
                            position='center'
                            fill={fill_real}
                            />
                        </Pie>
                        <Tooltip cursor={false}/>
                    </PieChart>
                </ResponsiveContainer>
                </section>
                <section className='2xl:w-full space-x-2 sm:space-x-0 w-1/2 flex sm:flex-col 2xl:flex-row flex-row pt-7 pl-4 pr-5'>
                    <Legend 
                        title={'Realised Savings'}
                        fill={fill_real}
                    />
                    <Legend 
                        title={'Planned Savings'}
                        fill={fill_planned}
                    />
                    <Legend 
                        title={`Total Specified ${unit}`}
                        fill={'#E2E8F0'}
                    />
                </section>
            </section>
        )
    }
)

export default ProgressWheel;
