import React from 'react';
import ReactTooltip from "react-tooltip";

export interface DisclaimerProps {
  title: string;
  content: string;
}

const Disclaimer: React.FC<DisclaimerProps> = React.memo(
  ({title, content}) => {

    return (
        <section className='container mx-auto flex flex-col p-2 px-6' style={{borderColor: "#94A3B8"}}>
            <hr className='stroke-1'/>
            <section className='flex flex-row pt-2'>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 9V11M12 15H12.01M5.07205 19H18.928C20.468 19 21.43 17.333 20.66 16L13.732 4C12.962 2.667 11.038 2.667 10.268 4L3.34005 16C2.57005 17.333 3.53205 19 5.07205 19Z" stroke="#475569" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <section className='flex flex-row relative'>
                  <h1 className='pl-2 text-md font-semibold pr-1 pb-2'>Important: </h1>
                  <h1 className=' text-md pl-2 text-gray-400'> All savings from reused materials in this report are from </h1>
                  <h1 className='font-semibold text-md pl-1 text-gray-400'> after October 2020 - </h1>
                  <div className=' text-md pl-1 text-gray-400 cursor-default underline' data-tip data-for={content}> Find out more</div>
                  <ReactTooltip
                    className='max-w-lg'
                    id={content} 
                    place='top' 
                    effect='solid'
                    delayHide={300}
                    delayShow={200}
                    multiline={true}
                    backgroundColor={'#4A5568'}>
                    <h1 className='font-medium text-lg pb-2'>{title}</h1>
                    {content}
                  </ReactTooltip>
                </section>
            </section>
        </section>
    )
  }
);

export default Disclaimer;
