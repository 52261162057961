import React from 'react';

const PageLoading: React.FC = () => {
    return(
        <div className='bg-gray-100 flex flex-col justify-center' style={{ height: '100vh'}}>
            <div className='animate-pulse flex flex-row justify-center sm:py-12'>
                <svg width="100" height="80" viewBox="0 0 70 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.5521 22.5335L21.6555 18.4134L29.7588 22.5335L21.6555 26.6536L13.5521 22.5335Z" fill="#94A3B8"/>
                    <path d="M34.02 63.5252L33.9502 55.2849L21.6555 49V26.6536L13.5521 22.5335V52.8408L34.02 63.5252Z" fill="#64748B"/>
                    <path d="M54.9071 44.6006V52.8408L34.02 63.5252L33.9502 55.2849L45.1272 49.6285L54.9071 44.6006Z" fill="#94A3B8"/>
                    <path d="M18.7913 0.955322L0 10.5922L8.03348 14.7123L18.7913 9.19555L45.9655 23.0224L54.1386 18.9721L18.7913 0.955322Z" fill="#64748B"/>
                    <path d="M0 28.3296L8.03348 32.7291V14.7123L0 10.5922V28.3296Z" fill="#94A3B8"/>
                    <path d="M69.4372 11.7095L61.4037 15.8296V23.6509L34.1597 37.5475L28.0822 34.5447V42.6453L34.1597 45.7179L69.4372 27.771V19.6006V11.7095Z" fill="#64748B"/>
                    <path d="M50.2267 1.93298L42.1932 6.05309L61.4037 15.8296L69.4372 11.7095L50.2267 1.93298Z" fill="#94A3B8"/>
                </svg>
            </div>
            <h1 className='animate-pulse flex flex-row justify-center pl-4 pt-1 font-semibold tracking-wider text-md text-gray-600'> Loading insights... </h1>
        </div>
    )
};

export default PageLoading;
