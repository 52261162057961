import React, { useState } from 'react';

import { Location } from '../../API';
import SimpleSavingsTile from './subComponents/SimpleSavingsTile';
import MaterialStatusTile from './subComponents/MaterialStatusTile';

export type LocationSavingsProps = {
    locations: Location[] | any;
}

const TotalSavingsContainer: React.FC<LocationSavingsProps> = React.memo(
    ({locations}) => {

        const [choices, setChoices] = useState<Location>(locations && locations[0] ? locations[0] : ['Choose..']);

        const location_name = choices? choices.name : '';

        const real_value = (
            (choices.value? choices.value.arrived? choices.value.arrived.value : 0 : 0) 
            + (choices.value? choices.value.departed? choices.value.departed.value : 0 : 0)
        );
        
        const planned_value = (
            (choices.value? choices.value.incoming? choices.value.incoming.value : 0 : 0)
            + (choices.value? choices.value.outgoing? choices.value.outgoing.value : 0 : 0)
        );

        const real_emission = (
            (choices.co2? choices.co2.arrived? choices.co2.arrived.value : 0 : 0) 
            + (choices.co2? choices.co2.departed? choices.co2.departed.value : 0 : 0)
        );
        
        const planned_emission = (
            (choices.co2? choices.co2.incoming? choices.co2.incoming.value : 0 : 0)
            + (choices.co2? choices.co2.outgoing? choices.co2.outgoing.value : 0 : 0)
        );

        const real_waste = (
            (choices.weight? choices.weight.arrived? choices.weight.arrived.value : 0 : 0) 
            + (choices.weight? choices.weight.departed? choices.weight.departed.value : 0 : 0)
        );
        
        const planned_waste = (
            (choices.weight? choices.weight.incoming? choices.weight.incoming.value : 0 : 0)
            + (choices.weight? choices.weight.outgoing? choices.weight.outgoing.value : 0 : 0)
        );

        return(
            <section className='border container mx-auto flex flex-col' style={{borderColor: "#CBD5E1"}}>
                <section className='flex flex-col xl:flex-row text-xl px-6'>
                    <h1 className='pt-6 mr-4'> Location Specifics for  </h1>
                    <select  
                        className='rounded-md px-2 mt-4 py-2 border-1 border-gray-300'
                        onChange={(e) => setChoices(locations ? locations.find((u: Location) => u.name === e.target.value) : null)} 
                    >
                        {
                            locations ? locations.map((loc: Location) => (
                                <option value={loc.name}>
                                {loc.name}
                            </option>
                            )) : ''
                        }
                    </select>
                </section>
                <section className='w-full px-6 pt-6 pb-8 flex flex-col 2xl:flex-row space-y-4 2xl:space-x-10 2xl:space-y-0'>
                    <MaterialStatusTile 
                        title = {'Materials Departed'}
                        departed = {choices ? choices.amount ? choices.amount.departed : 0 : 0}
                        reserved = {choices ? choices.amount ? choices.amount.outgoing : 0 : 0}
                        additional = {'of total reserved'}
                        tooltip = {'These are materials that were reserved from this location, and have been transported to their respective destination. The remainder are materials that have yet to leave their origin location.'}
                    />
                    <MaterialStatusTile 
                        title = {'Materials Arrived'}
                        departed = {choices ? choices.amount ? choices.amount.arrived : 0 : 0}
                        reserved = {choices ? choices.amount ? choices.amount.incoming : 0 : 0}
                        additional = {'of total reserved'}
                        tooltip = {'These are materials that were reserved to this location, and have been taken in use. The remainder are materials that are reserved, but have yet to arrive at this location.'}
                    />
                    <MaterialStatusTile 
                        title = {'Materials in Use'}
                        departed = {choices ? choices.residing_amount_in_use ? choices.residing_amount_in_use : 0 : 0}
                        reserved = {choices ? choices.residing_amount_available ? choices.residing_amount_available : 0 : 0}
                        additional = {'of total at location'}
                        tooltip = {'These are materials that are currently being used at this location. The remainder are all other materials, and include those available for others, and those marked for recycling.'}
                    />
                </section>

                <hr className='stroke-1 ml-6 mr-6'/>
                <h1 className='text-xl pl-6 pt-4'> Estimated Savings for {choices.name}  </h1>

                <section className='w-full px-6 flex flex-col 2xl:flex-row space-y-4 2xl:space-x-10 2xl:space-y-0 pt-6 pb-8'>
                    <SimpleSavingsTile 
                        title = {'Financial Savings'}
                        unit = {'NOK'}
                        type = {'kr'}
                        real = {real_value}
                        planned = {planned_value}
                        total = {0}
                        tooltip = {`Realised financial savings are the accumulated value of materials that have been reserved to ${location_name} and taken in use, as well as materials that have been reserved from ${location_name}, and have been taken in use elsewhere. The planned savings include materials that are reserved to ${location_name} but have yet to arrive, as well as materials reserved to other locations and have yet to be transported. When these materials depart or arrive at the location the planned savings become realised.`}
                    />
                    <SimpleSavingsTile 
                        title = {'Emission Savings'}
                        unit = {'Co2'}
                        type = {'kg'}
                        real = {real_emission}
                        planned = {planned_emission}
                        total = {0}
                        tooltip = {`Realised emission savings is the accumulated co2 in kg from materials that have been reserved to ${location_name} and taken in use, as well as materials that have been reserved from ${location_name}, and have been taken in use elsewhere. The planned savings include co2 from materials that are reserved to ${location_name} but have yet to arrive, as well as materials reserved to other locations and have yet to be transported. When these materials depart or arrive at the location the planned co2 savings become realised.`}
                    />
                    <SimpleSavingsTile 
                        title = {'Waste Savings'}
                        unit = {'Waste'}
                        type = {'kg'}
                        real = {real_waste}
                        planned = {planned_waste}
                        total = {0}
                        tooltip = {`Realised waste savings is the accumulated weight from materials that have been reserved to ${location_name} and taken in use, as well as materials that have been reserved from ${location_name}, and have been taken in use elsewhere. The planned savings include waste from materials that are reserved to ${location_name} but have yet to arrive, as well as materials reserved to other locations and have yet to be transported. When these materials depart or arrive at the location the planned waste savings become realised.`}
                    />
                </section>
            </section>
        )
    }
)

export default TotalSavingsContainer;
