import React from 'react';

import InfoIcon from './InfoIcon';
import ProgressWheel from '../../graphs/ProgressWheel';

export type SavingsTileProps = {
    title: string,
    unit: string,
    type: string,
    real: number,
    planned: number,
    total: number,
    tooltip: string,
    fill_real: string,
    fill_planned: string,
}

const SavingsTile: React.FC<SavingsTileProps> = React.memo(
    ({title, unit, type, real, planned, total, tooltip, fill_real, fill_planned}) => {
        return(
            <section className='w-full 2xl:w-1/3 pl-4 pb-3 flex flex-col bg-white shadow rounded-md'>
                <section className='relative flex flex-row'>
                    <h1 className='text-xl pl-2 pt-7 pb-4'>{title}</h1>
                    <InfoIcon margin={'8'} title={title} content={tooltip}/>
                </section>
                <hr className='stroke-1 ml-2 mr-6'/>
                <section className='relative flex flex-col'>
                    <section className='flex flex-col pt-4 pl-2 pb-6 tracking-widest items-center'>
                        <h1 className='text-3xl font-semibold' style={{color: "#4A5568"}}>{`${real.toLocaleString()} ${type}`}</h1>
                        <h1 className='text-sm pt-2 text-gray-400'>In Realised Savings</h1>
                    </section>
                    <hr className='stroke-1 ml-2 mr-6 pb-3'/>
                    <ProgressWheel
                        real={real}
                        planned={planned}
                        total={total}
                        unit={unit}
                        type={type}
                        fill_real={fill_real}
                        fill_planned={fill_planned}
                        description={''}
                    />
                </section>
            </section>
        )
    }
)

export default SavingsTile;
