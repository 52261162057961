import React from 'react';

import ReactTooltip from "react-tooltip";

export type PercentageProps = {
    title: string;
    category: number;
    total: number;
    tooltip: string;
}

const Percentage: React.FC<PercentageProps> = React.memo(
    ({title, category, total, tooltip}) => {

        const percentage = (category / total)*100;

        return(
            <section>
                <section className='absolute right-0 rounded-3xl items-center mt-2 mr-4 cursor-default' style={{backgroundColor: '#F0F9FF'}} data-tip data-for={title}>
                    <h1 className='p-1 pt-2.5 mb-1 px-2.5 text-sm font-medium' style={{color: '#0369A1'}}> {percentage.toFixed(1)}%</h1>
                </section>
                <ReactTooltip
                    className='max-w-lg'
                    id={title} 
                    place='top' 
                    effect='solid'
                    delayHide={300}
                    delayShow={200}
                    multiline={true}
                    backgroundColor={'#4A5568'}>
                    <h1 className='font-medium text-lg pb-2'>{title}</h1>
                    {tooltip}
                </ReactTooltip>
            </section>
        )
    }
);

export default Percentage;
