import React from 'react';

import Loopfront from '../report/subComponents/Loopfront';


const Footer: React.FC = () => {

    function handleClick() {
        window.location.replace('https://www.loopfront.com');
    }

    function handleClickTerms() {
        window.location.replace('https://www.loopfront.com/en/terms');
    }

    return(
        <section className='flex flex-col items-center pt-10 pb-5'>
            <h1 className='gray-100 text-gray-500 text-medium pb-2' > Made with </h1>
            <section className='cursor-pointer' onClick={() => handleClick()}>
                <Loopfront />
            </section>
            <h1 className='gray-100 text-gray-500 text-medium pt-2' > Try it for 30 days - Free! </h1>
            <h1 className='font-medium gray-100 text-gray-400 text-xs p-2' > Loopfront AS - Org. 921 471 564 </h1>
            <section className='cursor-pointer' onClick={() => handleClickTerms()}>
                <h1 className='font-medium gray-100 text-gray-400 text-xs p-2 pb-6' > Terms and Conditions </h1>
            </section>
        </section>
    )
};

export default Footer;
