import React from 'react';

import InfoIcon from './InfoIcon';

export type SimpleSavingsTileProps = {
    title: string,
    unit: string,
    type: string,
    real: number,
    planned: number,
    total: number,
    tooltip: string,
}

const SimpleSavingsTile: React.FC<SimpleSavingsTileProps> = React.memo(
    ({title, unit, type, real, planned, total, tooltip}) => {
        return(
            <section className='w-full 2xl:w-1/3 pl-4 flex flex-col bg-white shadow rounded-md'>
                <section className='relative flex flex-row'>
                    <h1 className='text-xl pl-2 pt-7'>{title}</h1>
                    <InfoIcon margin={'8'} title={title} content={tooltip}/>
                </section>
                <section className='w-full flex flex-col tracking-wider pl-2 pb-5'>
                    <h1 className='text-md font-semibold pt-2 pb-4 text-gray-400'>Realised | Planned ({unit}):</h1>
                    <section className='flex flex-row'>
                        <h1 className='text-3xl font-semibold' style={{color: "#4A5568"}}>{`${real.toLocaleString()} ${type}`}</h1>
                        <h1 className='text-xl font-semibold pt-2 pl-3 text-gray-400'>{` ${planned.toLocaleString()} ${type}`}</h1>
                    </section>
                </section>
            </section>
        )
    }
)

export default SimpleSavingsTile;
